.name-title {
    font-variant: small-caps;
    font-size: 11.5rem;
    line-height: 0.6;
    top: 1vh; /* Adjust based on your layout */
    transform: translateX(-50%); /* Center based on element's width */
    z-index: 1; /* Ensure it's above other content */

}


@media (max-width: 1559px) {
    .name-title {

        transform: translateX(0%); /* Adjust translation to re-center */
        transform-origin: center; /* Reset transform-origin if needed */

    }

    .name-title span {
        display: block; /* Make each word a block to stack them */
    }
}

@media (max-width: 1000px) {
    .name-title {
        font-size: 10rem;

        transform: translateX(0%); /* Adjust translation to re-center */
        transform-origin: center; /* Reset transform-origin if needed */

    }

    .name-title span {
        display: block; /* Make each word a block to stack them */
    }
}

@media (max-width: 845px) {
    .name-title {
        font-size: 7rem;
        transform: translateX(0%); /* Adjust translation to re-center */
        transform-origin: center; /* Reset transform-origin if needed */

    }

    .name-title span {
        display: block; /* Make each word a block to stack them */
    }
}

@media (max-width: 500px) {
    .name-title {
        font-size: 5rem;
        transform: translateX(0%); /* Adjust translation to re-center */
        transform-origin: center; /* Reset transform-origin if needed */

    }

    .name-title span {
        display: block; /* Make each word a block to stack them */
    }
}

@media (min-width: 1560px) {
    .name-title {
        position: fixed;
        top: 0;
        left: 101%;
        transform: rotate(90deg) translateX(0%);
        transform-origin: top left;
        letter-spacing: -10px;
    }

    .name-title span {
        display: inline; /* Default display inline */
    }

}

/*navigation*/
.nav-title {

    font-variant: small-caps;
    line-height: 0.6;
    transform: translateX(-50%); /* Center based on element's width */
    z-index: 3; /* Ensure it's above other content */

}


@media (max-width: 1559px) {
    .nav-title {
        top: -5vh; /* Adjust based on your layout */
        left: 20px;
        font-size: 8rem;
        position: relative;
        transform: translateY(0%); /* Adjust translation to re-center */
        transform-origin: top; /* Reset transform-origin if needed */
    }

}



@media (min-width: 1560px) {
    .nav-title {
        font-size: 10rem;
        position: fixed;
        top: calc(100% - 50px);
        left: 100px;
        transform: rotate(-90deg) translateY(-50%);
        transform-origin: top left;
        letter-spacing: -10px;

    }
}

@media (max-width: 1000px) {
        .nav-title {
            top: -5vh; /* Adjust based on your layout */
            left: 20px;
            font-size: 5rem;
            position: relative;
            transform: translateY(0%); /* Adjust translation to re-center */
            transform-origin: top; /* Reset transform-origin if needed */
        }

}

@media (max-width: 500px) {
    .nav-title {
        top: -4vh; /* Adjust based on your layout */
        left: 2px;
        font-size: 3rem;
        position: relative;
        transform: translateY(0%); /* Adjust translation to re-center */
        transform-origin: top; /* Reset transform-origin if needed */
    }

}

/*about-portrait*/
.portrait-big{
    width: auto;
    max-height: 600px;
    height: 100%;
    filter:grayscale(100%);
    border-radius: 7px;
    transform: scale(1.2) translateX(-33%) translateY(5%);
    overflow: hidden;

}

.portrait{
    width: auto;
    max-height: 250px;
    height: 100%;
    filter:grayscale(100%);
    border-radius: 7px;
    transform: scale(1);
    overflow: hidden;

}
@media (max-width: 769px) {
    .portrait {
        max-width: 250px;
        height: auto;
        transform:scale(1);
    }
}
@media (max-width: 484px) {
    .portrait {
        display: none;
    }
}

/*header*/
.header-background {
    height: 50vh;
    background-image:
            linear-gradient(
                    to bottom,
                    rgba(228, 179, 99, 0) 0%, /* Start transparent */
                    rgba(228, 179, 99, 1) 100% /* End with the solid color */
            ),
            url('../public/headermax.png');
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: -45% 40%;
    width: 100vw;

}


html {
    scroll-behavior: smooth;
}